<template>
  <hero-navigation>
    <div class="projects-nav-elements">
      <div class="projects-nav-elements">
        <router-link class="lead-center-overview-link" :to="{ name: 'lead-center-overview' }">
          <img style="width: 44px; height: 44px" src="/eye.png" />
        </router-link>
      </div>
      <platform-selector-part />
      <active-platform-part v-show="selectedPlatform._id !== 'all'" />
      <router-link v-show="selectedPlatform._id !== 'all'" class="button-outline-blue" to="/lead-center/settings" @click.native.prevent="$redirectTo('/lead-center/settings/platform')">
        Settings
      </router-link>
      <router-link class="button-outline-blue" to="/lead-center/activities">
        Activities
      </router-link>
      <router-link class="button-outline-blue" to="/lead-center/contacts">
        Contacts
      </router-link>
      <router-link v-show="selectedPlatform._id !== 'all'" class="button-outline-blue" to="/lead-center/kpi">
        Kpi
      </router-link>
      <router-link style="margin-left: auto" class="button-outline-blue" to="/lead-center/workflows">
        Workflows
      </router-link>
      <button class="button-outline-blue" style="margin: 0" v-b-toggle.sidebar-right>
        Logs
      </button>
      <b-sidebar id="sidebar-right" title="Logs" right shadow>
        <div class="px-3 py-2">
          <journal-component />
        </div>
      </b-sidebar>
    </div>
  </hero-navigation>
</template>
<script>
import HeroNavigation from '../components/HeroNavigation.vue'
import { mapGetters } from 'vuex'
// Parts
import PlatformSelectorPart from '@/agGridV2/parts/platform-selector.part.vue'
import ActivePlatformPart from '@/agGridV2/parts/active-platform.part.vue'
import JournalComponent from '@/agGridV2/components/journal.component.vue'

export default {
  name: 'lead-center-navigation',
  data() {
    return {}
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  methods: {
    ...mapGetters(['me', 'isAdmin', 'isManager', 'isManagerAndBigger', 'getSelectedPlatform']),
  },
  components: {
    HeroNavigation,
    PlatformSelectorPart,
    ActivePlatformPart,
    JournalComponent
  }
}
</script>

<style lang="scss" scoped>
.lead-center-overview-link {
  z-index: 1000;
}
.god-eye {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.god-eye:hover {
  transform: scale(10);
  filter: drop-shadow(0 0 10px rgba(255, 165, 0, 0.6)) drop-shadow(0 0 20px rgba(255, 165, 0, 0.5)) drop-shadow(0 0 30px rgba(255, 165, 0, 0.4));
}
</style>
